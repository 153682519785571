import React from 'react';

import TimerContext from '../context/TimerContext';
import Heading from '../atoms/Heading';

const Timer = ({ time }) => {
  let hrs = Math.floor(time / 3600);
  let min = Math.floor((time - hrs * 3600) / 60);
  let sec = time - hrs * 3600 - min * 60;

  hrs = hrs < 10 ? `0${hrs}` : hrs;
  min = min < 10 ? `0${min}` : min;
  sec = sec < 10 ? `0${sec}` : sec;

  return (
    <Heading headingSize={3} italic as="div">
      {`Session: ${hrs}:${min}:${sec}`}
    </Heading>
  );
};

export default () => (
  <TimerContext.Consumer>
    {({ timeOnSite }) => <Timer time={timeOnSite} />}
  </TimerContext.Consumer>
);

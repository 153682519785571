import React from 'react';

const defaultContext = {
  timeOnSite: 0,
};

const Context = React.createContext(defaultContext);
const { Consumer } = Context;

class Provider extends React.Component {
  timer = null;

  incrementTimeOnSite = () => {
    this.setState(prevState => ({
      timeOnSite: prevState.timeOnSite + 1,
    }));
  };

  state = defaultContext;

  componentDidMount() {
    this.timer = setInterval(this.incrementTimeOnSite, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  render() {
    return (
      <Context.Provider value={this.state}>
        {this.props.children}
      </Context.Provider>
    );
  }
}

export default { Consumer, Provider };

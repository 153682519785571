import gql from 'graphql-tag';

export const GET_AVAILABLE_LOCATIONS = gql`
    query {
        localization {
            availableCountries {
                currency {
                    isoCode
                    name
                    symbol
                }
                isoCode
                name
                unitSystem
            }
        }
    }
`
import React, { createContext, useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';

const defaultState = {
  currentCategory: 'ALL',
  categoryOptions: [],
};

const ProductCategoriesContext = createContext(defaultState);

export const ProductCategoriesProvider = props => {
  const [currentCategory, setCategory] = useState(defaultState.currentCategory);

  const { settings } = useStaticQuery(graphql`
    {
      settings: allPrismicSiteSettings {
        edges {
          node {
            data {
              categories {
                active
                label
                shopify_handle
              }
            }
          }
        }
      }
    }
  `);

  const categoryOptions = [
    {
      label: 'All Products',
      shopify_handle: 'ALL',
    },
    ...settings.edges[0].node.data.categories.filter(option => option.active),
  ];

  return (
    <ProductCategoriesContext.Provider
      value={[currentCategory, setCategory, categoryOptions]}
      {...props}
    />
  );
};

export default ProductCategoriesContext;

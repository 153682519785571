/*
read: https://medium.com/@david.gilbertson/icons-as-react-components-de3e33cb8792
use: https://jakearchibald.github.io/svgomg/
*/

import React from 'react';

const icons = {
  arrowsLeft:
    '<defs><linearGradient id="a" x1="50%" x2="50%" y1="0%" y2="98.68557%"><stop offset="0%" stop-color="#696969"/><stop offset="100%" stop-color="#1E1E1E"/></linearGradient></defs><path fill="url(#a)" d="M11.23068 10L15 13.88889 13.92305 15l-4.84627-5 4.84627-5L15 6.11111 11.23068 10zM6.1539 10l3.76932 3.88889L8.84627 15 4 10l4.84627-5 1.07695 1.11111L6.1539 10z"/>',

  arrowsRight:
    '<defs><linearGradient id="b" x1="50%" x2="50%" y1="0%" y2="98.68557%"><stop offset="0%" stop-color="#696969"/><stop offset="100%" stop-color="#1E1E1E"/></linearGradient></defs><path fill="url(#b)" d="M8.76932 10L5 6.11111 6.07695 5l4.84627 5-4.84627 5L5 13.88889 8.76932 10zm5.07678 0l-3.76932-3.88889L11.15373 5 16 10l-4.84627 5-1.07695-1.11111L13.8461 10z"/>',

  back:
    '<defs><linearGradient id="c" x1="100%" x2="0%" y1="0%" y2="100%"><stop offset="0%" stop-color="#C4C4C4"/><stop offset="100%" stop-color="gray"/></linearGradient></defs><path fill="url(#c)" d="M12.5 13.88889L11.4091 15 6.5 10l4.9091-5L12.5 6.11111 8.68182 10z"/>',

  cart:
    '<defs><linearGradient id="d" x1="50%" x2="50%" y1="0%" y2="98.68557%"><stop offset="0%" stop-color="#696969"/><stop offset="100%" stop-color="#1E1E1E"/></linearGradient></defs><path fill="url(#d)" fill-rule="evenodd" d="M5.127 2H2v1.1531h2.21173L5.88043 14h9.87367v-1.1531H6.79748l-.17981-1.2223h9.62553L18 4.02562H5.43987L5.127 2zM14.5 15c-.8283 0-1.5.67155-1.5 1.5s.6717 1.5 1.5 1.5c.82845 0 1.5-.67155 1.5-1.5s-.67155-1.5-1.5-1.5zm-7 0c-.82845 0-1.5.67155-1.5 1.5S6.67155 18 7.5 18 9 17.32845 9 16.5 8.32845 15 7.5 15z"/>',

  cartLight:
    '<defs><linearGradient id="g" x1="50%" x2="50%" y1="23.73352%" y2="98.68557%"><stop offset="0%" stop-color="#C4C4C4"/><stop offset="100%" stop-color="gray"/></linearGradient></defs><path fill="url(#g)" fill-rule="evenodd" d="M5.127 2H2v1.1531h2.21173L5.88043 14h9.87367v-1.1531H6.79748l-.17981-1.2223h9.62553L18 4.02562H5.43987L5.127 2zM14.5 15c-.8283 0-1.5.67155-1.5 1.5s.6717 1.5 1.5 1.5c.82845 0 1.5-.67155 1.5-1.5s-.67155-1.5-1.5-1.5zm-7 0c-.82845 0-1.5.67155-1.5 1.5S6.67155 18 7.5 18 9 17.32845 9 16.5 8.32845 15 7.5 15z"/></svg>',

  slideshow:
    '<defs><linearGradient id="e" x1="50%" x2="50%" y1="44.10777%" y2="98.68557%"><stop offset="0%" stop-color="#C4C4C4"/><stop offset="100%" stop-color="gray"/></linearGradient></defs><rect width="10" height="14" x="5" y="3" fill="url(#e)" fill-rule="evenodd" rx="1"/>',

  thumbnails:
    '<defs><linearGradient id="f" x1="50%" x2="50%" y1="44.10777%" y2="98.68557%"><stop offset="0%" stop-color="#C4C4C4"/><stop offset="100%" stop-color="gray"/></linearGradient></defs><g fill="url(#f)" fill-rule="evenodd"><rect width="4" height="4" x="2" y="2" rx="1"/><rect width="4" height="4" x="2" y="8" rx="1"/><rect width="4" height="4" x="2" y="14" rx="1"/><rect width="4" height="4" x="8" y="2" rx="1"/><rect width="4" height="4" x="8" y="8" rx="1"/><rect width="4" height="4" x="8" y="14" rx="1"/><rect width="4" height="4" x="14" y="2" rx="1"/><rect width="4" height="4" x="14" y="8" rx="1"/><rect width="4" height="4" x="14" y="14" rx="1"/></g>',

  close:
    '<defs><linearGradient id="g" x1="69.916%" x2="69.916%" y1="32.122%" y2="100%"><stop offset="0%" stop-color="#C4C4C4"/><stop offset="100%" stop-color="gray"/></linearGradient></defs><path fill="url(#g)" fill-rule="evenodd" d="M10 8.636L13.636 5 15 6.364 11.364 10 15 13.636 13.636 15 10 11.364 6.364 15 5 13.636 8.636 10 5 6.364 6.364 5z"/>',

  plus:
    '<defs><linearGradient id="h" x1="50%" x2="50%" y1="0%" y2="98.686%"><stop offset="0%" stop-color="#696969"/><stop offset="100%" stop-color="#1E1E1E"/></linearGradient></defs><path fill="url(#h)" fill-rule="nonzero" d="M11 11v4H9v-4H5V9h4V5h2v4h4v2h-4z"/>',

  minus:
    '<defs><linearGradient id="i" x1="50%" x2="50%" y1="0%" y2="98.686%"><stop offset="0%" stop-color="#696969"/><stop offset="100%" stop-color="#1E1E1E"/></linearGradient></defs><path fill="url(#i)" fill-rule="nonzero" d="M15 9v2H5V9z"/>',

  info:
    '<defs><linearGradient id="j" x1="50%" x2="50%" y1="98.686%" y2="27.086%"><stop offset="0%" stop-color="#9B9B9B"/><stop offset="100%" stop-color="#414141"/></linearGradient><linearGradient id="k" x1="50%" x2="50%" y1="100%" y2="50%"><stop offset="0%" stop-color="#FFF"/><stop offset="100%" stop-color="#E6E6E6"/></linearGradient></defs><g fill="none" fill-rule="evenodd"><circle cx="10" cy="10" r="10" fill="url(#j)"/><path fill="url(#k)" d="M9 8.392h2v7.028H9V8.392zM10 7a1.25 1.25 0 1 1 0-2.5A1.25 1.25 0 0 1 10 7z"/></g>',

  check:
    '<defs><linearGradient id="l" x1="50%" x2="50%" y1="27.086%" y2="98.686%"><stop offset="0%" stop-color="#9B9B9B"/><stop offset="100%" stop-color="#414141"/></linearGradient></defs><path fill="url(#l)" fill-rule="evenodd" d="M9.192 11.48l4.362-5.2 1.04.873-5.234 6.238-3.64-3.053.873-1.04 2.6 2.181zM10 19a9 9 0 1 1 0-18 9 9 0 0 1 0 18z"/>',

  infoInverted:
    '<defs><linearGradient id="m" x1="50%" x2="50%" y1="23.734%" y2="98.686%"><stop offset="0%" stop-color="#C4C4C4"/><stop offset="100%" stop-color="gray"/></linearGradient></defs><path fill="url(#m)" fill-rule="evenodd" d="M10 20C4.477 20 0 15.523 0 10S4.477 0 10 0s10 4.477 10 10-4.477 10-10 10zM9 8.392v7.028h2V8.392H9zM10 7a1.25 1.25 0 1 0 0-2.5A1.25 1.25 0 0 0 10 7z"/>',

  arrowDown:
    '<defs><linearGradient id="n" x1="100%" x2="0%" y1="0%" y2="100%"><stop offset="0%" stop-color="#C4C4C4"/><stop offset="100%" stop-color="gray"/></linearGradient></defs><path fill="url(#n)" fill-rule="nonzero" transform="rotate(-90 10 10)" d="M13 13.89L11.9 15 7 10l4.9-5L13 6.11 9.18 10z"/>',

  arrowUp:
    '<defs><linearGradient id="o" x1="100%" x2="0%" y1="0%" y2="100%"><stop offset="0%" stop-color="#C4C4C4"/><stop offset="100%" stop-color="gray"/></linearGradient></defs><path fill="url(#o)" fill-rule="nonzero" transform="rotate(90 10 10)" d="M13 13.89L11.9 15 7 10l4.9-5L13 6.11 9.18 10z"/>',

  environment:
    '<defs><linearGradient x1="50%" y1="23.734%" x2="50%" y2="98.686%" id="p"><stop stop-color="#C4C4C4" offset="0%" /><stop stop-color="gray" offset="100%" /></linearGradient></defs><path d="M10 2a8 8 0 110 16 8 8 0 010-16zm0 2a6 6 0 100 12 6 6 0 000-12zm-.376 8.814l.002 2.37c-.662-.236-1.284-1.076-1.684-2.276a18.131 18.131 0 011.87-.095l-.188.001zm.754.003c.6.011 1.142.042 1.674.107-.398 1.19-1.018 2.024-1.677 2.259zm-3.192.188c.237.753.56 1.403.945 1.902a5.267 5.267 0 01-2.248-1.648c.318-.078.651-.147.998-.206zm5.616.031c.404.075.814.172 1.252.3a5.267 5.267 0 01-2.184 1.572c.356-.463.659-1.054.89-1.737zm2.442-2.786a5.213 5.213 0 01-.733 2.437c-.473-.14-.98-.26-1.516-.353.146-.64.234-1.336.252-2.067l-.001-.018zm-8.491 0c.016.713.099 1.394.238 2.022-.682.106-1.323.249-1.91.425l.354-.101-.126-.236a5.216 5.216 0 01-.532-1.829l-.021-.281zm5.744 0a9.703 9.703 0 01-.244 1.968c-.6-.079-1.229-.129-1.877-.147l-.001-1.822zm-2.872-.001v1.814c-.65.006-1.282.044-1.887.113a9.672 9.672 0 01-.235-1.91v-.017h2.122zm-4.187-2.85c.487.13 1.007.24 1.554.323a10.336 10.336 0 00-.23 1.777H4.773a5.217 5.217 0 01.659-2.09zm2.3.422c.518.057 1.054.093 1.604.106l.283.003v1.569H7.512a9.665 9.665 0 01.19-1.508zm4.513-.044c.124.529.207 1.108.237 1.722h-2.113l.001-1.575c.537-.015 1.06-.052 1.566-.109zm2.257-.469c.39.652.643 1.396.718 2.191H13.24a10.337 10.337 0 00-.244-1.836c.43-.077.842-.169 1.233-.275zM9.626 4.817l-.001 2.365a18.059 18.059 0 01-1.681-.095l.06-.172c.399-1.104.993-1.874 1.622-2.098zm.75 0c.652.233 1.267 1.055 1.666 2.229-.533.067-1.09.11-1.666.128zm-2.246.275c-.383.499-.706 1.147-.942 1.9a14.197 14.197 0 01-1.665-.35l.364.094.187-.222c.451-.507.999-.927 1.615-1.23l.267-.121zm3.74 0c.855.327 1.604.87 2.178 1.565-.394.107-.814.2-1.254.276-.22-.68-.51-1.274-.853-1.746z" transform="translate(-2 -2)" fill="url(#p)" fill-rule="evenodd" />',
};

export default ({ type, size = 20, ...rest }) => (
  <svg
    viewBox={`0 0 ${size} ${size}`}
    {...rest}
    dangerouslySetInnerHTML={{ __html: icons[type] }} // eslint-disable-line react/no-danger
  />
);

import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import scale from '../utils/scale';
import media from '../utils/mediaQueries';

import Button from '../atoms/Button';

import Notice from './Notice';

const isBrowser = typeof window !== 'undefined';

const CookieNotice = styled(Notice).attrs(() => ({
  noIcon: true,
}))`
  z-index: 10;
  position: absolute;
  left: 0;
  bottom: ${props => scale(props.theme.height.chromeBarBottom)};
  margin: 0;
  border: 1px solid ${props => props.theme.color.borderDark};
  border-width: 1px 1px 0;
  border-top-color: ${props => props.theme.color.borderLight};
  width: 100%;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);

  ${media.smOnly`
    ${props =>
      !props.isIndex &&
      `
      bottom: 0;
    `}
  `}

  & > div {
    display: flex;
    flex-direction: column;

    ${media.md`
      flex-direction: row;
    `}
  }
`;

const AcceptWrapper = styled.div`
  display: flex;
  flex: 1 0 auto;
  margin-top: ${scale(1.5)};

  ${media.md`
    justify-content: flex-end;
    margin-top: 0;
  `}
`;

const MessageWrapper = styled.div`
  ${media.md`
    padding-right: ${scale(3)};
  `}
`;

export default class CookieNoticeAcceptance extends React.Component {
  setAcceptance = () => {
    if (isBrowser) {
      localStorage.setItem('cookies_accepted', true);
    }

    this.setState({
      isAccepted: true,
    });

    const event = new CustomEvent('localStorageChange', { detail: { 'cookies_accepted': true } });
    window.dispatchEvent(event);
  };

  state = {
    isAccepted: true,
  };

  componentDidMount() {
    // Check for an existing acceptance
    const isAccepted = isBrowser
      ? localStorage.getItem('cookies_accepted')
      : true;

    this.setState(() => (!isAccepted ? { isAccepted: false } : null));
  }

  render() {
    const hideNotice = this.state.isAccepted;

    return (
      <>
        {!hideNotice && (
          <CookieNotice {...this.props}>
            <MessageWrapper>
              <p>
                This website uses cookies to enable a personalized shopping
                experience and to analyze traffic.
                <br />
                By using this site you agree that we may store and access
                cookies on your device. To find out more and learn how to opt
                out, please visit our{' '}
                <Link to="/datenschutzerklaerung/">Privacy Policy</Link>.
              </p>
            </MessageWrapper>

            <AcceptWrapper>
              <Button
                icon="check"
                label="Accept and continue"
                onClick={this.setAcceptance}
              />
            </AcceptWrapper>
          </CookieNotice>
        )}
      </>
    );
  }
}

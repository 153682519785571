import React from 'react';
import styled from 'styled-components';

import RichText from '../atoms/RichText';
import Icon from '../atoms/Icon';

import scale from '../utils/scale';

const Notice = styled.div`
  position: relative;
  margin-bottom: ${scale(2)};
  padding: ${scale(1.5)};
  background: ${props =>
    props.type === 'error'
      ? props.theme.color.redLight
      : props.theme.color.blueLight};

  ${props =>
    !props.noIcon &&
    `
    padding-left: ${scale(4.5)};
  `}
`;

const NoticeIcon = styled(Icon).attrs({
  type: 'info',
})`
  position: absolute;
  top: ${scale(1)};
  left: ${scale(1)};
  width: 20px;
  height: 20px;

  ${props =>
    props.$singleLine &&
    `
    top: 50%;
    margin-top: -10px;
  `}
`;

export default ({ children, noIcon, singleLine = false, ...rest }) => (
  <Notice noIcon={noIcon} {...rest}>
    {!noIcon && <NoticeIcon $singleLine={singleLine} />}

    <RichText>{children}</RichText>
  </Notice>
);

import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';

import scale from '../utils/scale';
import media from '../utils/mediaQueries';
import ChromeBar from '../atoms/ChromeBar';
import Heading from '../atoms/Heading';
import FooterNavigation from './FooterNavigation';

const Bar = styled(ChromeBar)`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: ${props => scale(props.theme.height.chromeBarBottom)};
  transition: transform 0.3s ease-out;

  ${media.smOnly`
    ${props =>
      props.hideOnMobile &&
      `
      transform: translateY(${scale(props.theme.height.chromeBarBottom)});
    `}
  `}
`;

const Left = styled.div`
  display: none;
  padding: 0 ${scale(0.75)};

  ${media.md`
    width: 50%;
    display: block;
  `}
`;

const Right = styled.div`
  padding: 0 ${scale(0.75)} ${scale(0.5)};
  width: 100%;
  text-align: center;

  ${media.md`
    width: 50%;
    text-align: right;
  `}
`;

export default props => (
  <StaticQuery
    query={graphql`
      query ChromeBarBottomQuery {
        defaults: allPrismicSiteSettings {
          edges {
            node {
              data {
                bottom_bar_information_1
                bottom_bar_information_2

                # footer_navigation {
                #   document {
                #     id
                #   }
                # }
              }
            }
          }
        }
      }
    `}
    render={data => {
      const defaults = data.defaults.edges[0].node.data;
      const buildDate = new Date(parseInt(process.env.GATSBY_BUILD_TIME, 10));

      return (
        <Bar {...props}>
          <Left>
            <Heading headingSize={3} italic as="div">
              {defaults.bottom_bar_information_1}&emsp;
              {defaults.bottom_bar_information_2}{' '}
              {buildDate.toString().substr(4)}
            </Heading>
          </Left>

          <Right>
            <FooterNavigation />
          </Right>
        </Bar>
      );
    }}
  />
);

import React from 'react';
import { Link, StaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';

import Heading from '../atoms/Heading';

import scale from '../utils/scale';

const NavigationLink = styled(Link)`
  display: inline-block;
  margin-right: ${scale(1.5)};
  text-decoration: underline;
  text-decoration-style: dotted;
  color: ${props => props.theme.color.base};

  &:last-child {
    margin-right: 0;
  }

  &:hover,
  &:focus {
    text-decoration-style: solid;
  }
`;

export default () => (
  <StaticQuery
    query={graphql`
      query {
        navigation: allPrismicSiteSettings {
          edges {
            node {
              data {
                footer_navigation {
                  document {
                    __typename
                    ... on PrismicNavigation {
                      ...NavigationContent
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={data => {
      const navigation =
        data.navigation.edges[0].node.data.footer_navigation.document;

      return (
        <>
          {navigation.data.navigation_items
            .filter(item => item.item_status === 'active')
            .map(item => (
              <NavigationLink to={`/${item.item_slug}/`} key={item.item_slug}>
                <Heading headingSize={3} as="span">
                  {item.item_name}
                </Heading>
              </NavigationLink>
            ))}
        </>
      );
    }}
  />
);

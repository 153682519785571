import gql from 'graphql-tag';

const cartContentFragment = gql`
  fragment CartContent on Cart {
    id
    buyerIdentity {
      countryCode
    }
    checkoutUrl
    cost {
      checkoutChargeAmount {
        amount
        currencyCode
      }
      subtotalAmount {
        amount
        currencyCode
      }
      subtotalAmountEstimated
      totalAmount {
        amount
        currencyCode
      }
      totalAmountEstimated
      totalDutyAmount {
        amount
        currencyCode
      }
      totalDutyAmountEstimated
      totalTaxAmount {
        amount
        currencyCode
      }
      totalTaxAmountEstimated
    }
    createdAt
    totalQuantity
    updatedAt
    lines(first: 250) {
      edges {
        node {
          id
          quantity
          
          merchandise {
             ... on ProductVariant {
              id
              availableForSale
              title
              price {
                amount
                currencyCode
              }
              compareAtPrice {
                amount
                currencyCode
              }
              selectedOptions {
                value
              }
              sku
              image {
                transformedSrc(
                  crop: CENTER
                  maxWidth: 56
                  maxHeight: 76
                  scale: 3
                )
              }
              product {
                id
                title
               
              }
            }  
          }
        }
      }
    }
  }
`

export const CREATE_NEW_CART = gql`
  mutation ($countryCode: CountryCode!) {
    cartCreate(
      input: {
        buyerIdentity: {
          countryCode: $countryCode
        }
      }
    ){
      cart {
        id
        buyerIdentity {
          countryCode
        } 
      }
      userErrors {
        field
        message
      }
    }
  }
`

export const CREATE_NEW_CHECKOUT = gql`
mutation checkoutCreate($input: CheckoutCreateInput!) {
  checkoutCreate(input: $input) {
    checkout {
      id
      webUrl
    }
  }
}
`;

export const UPDATE_CART_BUYER_IDENTIY = gql`
mutation cartBuyerIdentityUpdate($buyerIdentity: CartBuyerIdentityInput!, $cartId: ID!, $countryCode: CountryCode!) @inContext(country: $countryCode)  {
  cartBuyerIdentityUpdate(buyerIdentity: $buyerIdentity, cartId: $cartId) {
    cart {
      ...CartContent
    }
    userErrors {
      field
      message
    }
  }
}
${cartContentFragment}
`

export const GET_CART = gql`
  query cart ($cartId: ID!)
   {
    node(id: $cartId)   {
      ... on Cart {
        ...CartContent
      }
    }
  }
  ${cartContentFragment}
`

export const GET_CART_WITH_COUNTRY_CODE = gql`
  query cart ($cartId: ID!, $countryCode: CountryCode!) @inContext(country: $countryCode)
   {
    node(id: $cartId)   {
      ... on Cart {
        ...CartContent
      }
    }
  }
  ${cartContentFragment}
`

export const ADD_CART_LINE_ITEMS = gql`
  mutation cartLinesAdd($cartId: ID!, $lines: [CartLineInput!]!) {
    cartLinesAdd(cartId: $cartId, lines: $lines) {
      cart {
        ...CartContent
      }
      userErrors {
        field
        message
      }
    }
  }
  ${cartContentFragment}
  `


export const UPDATE_CART_LINE_ITEMS = gql`
  mutation cartLinesUpdate($cartId: ID!, $lines: [CartLineUpdateInput!]!, $countryCode: CountryCode!) @inContext(country: $countryCode) {
    cartLinesUpdate(cartId: $cartId, lines: $lines) {
      cart {
        ...CartContent
      }
      userErrors {
        field
        message
      }
    }
  }
  ${cartContentFragment}
`



export const REMOVE_CART_LINE_ITEMS = gql`
  mutation cartLinesRemove($cartId: ID!, $lineIds: [ID!]!, $countryCode: CountryCode!) @inContext(country: $countryCode) { 
    cartLinesRemove(cartId: $cartId, lineIds: $lineIds) {
      cart {
        ...CartContent
      }
      userErrors {
        field
        message
      }
    }
  }
  ${cartContentFragment}
`
// `

// optimistic response helpers
const getOptimisticResponse = (oldData, lineItems, total) => ({
  __typename: 'Mutation',
  userErrors: {
    __typename: 'UserError',
    message: '',
    field: '',
  },
  checkout: {
    ...oldData.node,
    totalPrice: {
      ...oldData.node.totalPrice,
      amount: total.toString(),
    },
    lineItems: {
      ...oldData.node.lineItems,
      edges: lineItems,
    },
  },
});


const getTotal = (lineItems) =>
  lineItems.reduce(
    (total, { node }) =>
      total + node.quantity * parseFloat(node.variant.price.amount),
    0
  );

// optimistic response for UPDATE_LINE_ITEM
export const optimisticUpdateLineItem = (oldData, id, quantity) => {
  const updatedLineItems = oldData.node.lineItems.edges.map((edge) =>
    edge.node.id === id
      ? {
        ...edge,
        node: {
          ...edge.node,
          quantity,
        },
      }
      : edge
  );

  return {
    checkoutLineItemsUpdate: {
      ...getOptimisticResponse(
        oldData,
        updatedLineItems,
        getTotal(updatedLineItems)
      ),
    },
  };
};

// optimistic response for REMOVE_LINE_ITEM
export const optimisticRemoveLineItem = (oldData, id) => {
  const updatedLineItems = oldData.node.lines.edges.filter(
    (edge) => edge.node.id !== id
  );

  return {
    checkoutLineItemsRemove: {
      ...getOptimisticResponse(
        oldData,
        updatedLineItems,
        getTotal(updatedLineItems)
      ),
    },
  };
};

import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';

import media from '../utils/mediaQueries';
import scale from '../utils/scale';

import ChromeBar from '../atoms/ChromeBar';
import Heading from '../atoms/Heading';
import Text from '../atoms/Text';
import Timer from './Timer';

const Bar = styled(ChromeBar)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: ${props => scale(props.theme.height.chromeBarTop)};
  transition: transform 0.3s ease-out;

  ${media.smOnly`
    ${props =>
      props.hideOnMobile &&
      `
      transform: translateY(-${scale(props.theme.height.chromeBarTop)});
    `}
  `}
`;

const Left = styled.div`
  display: none;
  padding: 0 ${scale(0.75)};

  ${media.md`
    display: block;
    width: 25%;
  `}
`;

const Center = styled.div`
  padding: ${scale(0.125)} ${scale(0.75)} 0;
  width: 100%;
  text-align: center;
  text-shadow: 0 1px 0 #d5d5d5;

  ${media.md`
    width: 50%;
  `}
`;

const Right = styled.div`
  display: none;
  padding: 0 ${scale(0.75)};
  text-align: right;

  ${media.md`
    display: block;
    width: 25%;
  `}
`;

export default props => (
  <StaticQuery
    query={graphql`
      query ChromeBarTopQuery {
        defaults: allPrismicSiteSettings {
          edges {
            node {
              data {
                top_bar_title_left
                top_bar_title_center
              }
            }
          }
        }
      }
    `}
    render={data => {
      const defaults = data.defaults.edges[0].node.data;

      return (
        <Bar {...props}>
          <Left>
            <Heading headingSize={3} as="div">
              {defaults.top_bar_title_left}
            </Heading>
          </Left>

          <Center>
            <Text textSize="sm" bold extended truncate as="div">
              {defaults.top_bar_title_center}
            </Text>
          </Center>

          <Right>
            <Timer />
          </Right>
        </Bar>
      );
    }}
  />
);

import React from 'react';
import styled, { css } from 'styled-components';

const visuallyHidden = css`
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
`;

const base = css`
  padding: 0;
  margin: 0;
  font-style: ${props => (props.italic ? 'italic' : 'normal')};

  ${props =>
    props.visuallyHidden &&
    `
    ${visuallyHidden}
  `}
`;

export const h1 = css`
  ${base}
  font-family: ${props => props.theme.font.extended};
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.02em;
`;

export const h2 = css`
  ${base}
  font-family: ${props => props.theme.font.extended};
  font-weight: bold;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0;
`;

export const h3 = css`
  ${base}
  font-family: ${props => props.theme.font.base};
  font-weight: normal;
  font-size: 9px;
  line-height: 14px;
  letter-spacing: 0.03em;
`;

const Heading1 = styled.h1`
  ${h1}
`;

const Heading2 = styled.h2`
  ${h2}
`;

const Heading3 = styled.h3`
  ${h3}
`;

export default ({ headingSize, use, ...rest }) => {
  switch (headingSize) {
    case 3:
      return <Heading3 as={use} {...rest} />;
    case 2:
      return <Heading2 as={use} {...rest} />;
    default:
      return <Heading1 as={use} {...rest} />;
  }
};

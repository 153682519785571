import React from 'react';
import styled, { css } from 'styled-components';

const base = css`
  padding: 0;
  margin: 0;
  font-family: ${props =>
    props.extended ? props.theme.font.extended : props.theme.font.base};
  font-weight: ${props => (props.bold ? 'bold' : 'normal')};
  font-style: ${props => (props.italic ? 'italic' : 'normal')};

  ${props => {
    if (props.extended) {
      return 'letter-spacing: 0;';
    }
    if (props.italic) {
      return 'letter-spacing: 0.015em;';
    }
    if (props.bold) {
      return 'letter-spacing: 0.02em;';
    }
    return 'letter-spacing: 0.03em;';
  }}

  ${props =>
    props.truncate &&
    `
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  `}
`;

const TextBase = styled.p`
  ${base}
  font-size: 13px;
  line-height: 20px;
`;

const TextSmall = styled.p`
  ${base}
  font-size: 11px;
  line-height: 18px;
`;

export default ({ textSize, use, ...rest }) => {
  switch (textSize) {
    case 'sm':
      return <TextSmall as={use} {...rest} />;
    default:
      return <TextBase as={use} {...rest} />;
  }
};

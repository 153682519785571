import { css } from 'styled-components';

import theme from './theme';

const sizes = theme.breakpoints;
const sizeNames = ['sm', 'md', 'lg'];

// Iterate through the sizes and create a media template
const media = sizes.reduce((acc, size, i) => {
  acc[sizeNames[i]] = (...args) => css`
    @media (min-width: ${size}) {
      ${css(...args)}
    }
  `;

  return acc;
}, {});

media.xsOnly = (...args) => css`
  @media (max-width: ${sizes[0]}) {
    ${css(...args)}
  }
`;

media.smOnly = (...args) => css`
  @media (max-width: ${sizes[1]}) {
    ${css(...args)}
  }
`;

export default media;

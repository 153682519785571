import styled from 'styled-components';

export default styled.div`
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  align-items: center;
  border: 1px solid transparent;
  border-width: 0 1px;
  width: 100%;
  background: ${props => props.theme.gradient.chromeDark};

  * {
    cursor: default;
    user-select: none;
  }

  a {
    cursor: pointer;

    * {
      cursor: pointer;
    }
  }
`;

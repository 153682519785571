import React from 'react';
import styled from 'styled-components';

import scale from '../utils/scale';

import { h2, h3 } from './Heading';

const RichText = styled.div`
  font-family: ${props => props.theme.font.base};
  font-size: 13px;
  line-height: 20px;

  h2 {
    ${h3}
    margin-top: ${scale(4)};

    &:first-child {
      margin-top: 0;
    }
  }

  h3 {
    ${h2}
    margin-top: ${scale(4)};

    &:first-child {
      margin-top: 0;
    }
  }

  h2 + h3 {
    margin-top: ${scale(2)};
  }

  p {
    padding: 0;
    margin: 0;
    letter-spacing: 0.03em;

    a {
      color: ${props => props.theme.color.base};

      &:hover,
      &:focus {
        color: ${props => props.theme.color.blue};
      }

      &:active,
      &:visited {
        color: ${props => props.theme.color.purple};
      }
    }

    strong {
      font-family: ${props => props.theme.font.extended};
      font-weight: bold;
      letter-spacing: 0;
    }

    & + p {
      padding-top: ${scale(2)};
    }
  }

  ul {
    padding-left: ${scale(1.5)};
    list-style: none;

    li {
      position: relative;

      &::before {
        position: absolute;
        top: 0;
        left: -${scale(1.5)};
        content: '–';
      }
    }
  }
`;

export default props => (
  <>
    {props.htmlContent ? (
      <RichText
        dangerouslySetInnerHTML={{ __html: props.htmlContent }}
        {...props}
      /> // eslint-disable-line react/no-danger
    ) : (
      <RichText {...props} />
    )}
  </>
);

// Set scroll position on custom element instead of window
exports.shouldUpdateScroll = ({
  routerProps: { location: currentLocation },
}) => {
  if (currentLocation.state && currentLocation.state.scrollTo) {
    const scrollContainer = document.getElementById('scrollContainer');

    if (typeof scrollContainer !== 'undefined') {
      scrollContainer.scrollTop = currentLocation.state.scrollTo;
    }
  }

  return false;
};

import React from 'react';
import styled, { ThemeProvider } from 'styled-components';

import media from '../utils/mediaQueries';
import scale from '../utils/scale';
import theme from '../utils/theme';
import ThemeGlobalStyles from '../utils/themeGlobal';

import ApolloProvider from '../context/ApolloContext';
import TimerContext from '../context/TimerContext';
import { CartProvider } from '../context/CartContext';
import { ProductCategoriesProvider } from '../context/ProductCategoriesContext';

import CookieNotice from './CookieNotice';
import Head from './Head';
import ChromeBarTop from './ChromeBarTop';
import ChromeBarBottom from './ChromeBarBottom';

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: ${props => scale(props.theme.height.chromeBarTop)};
  padding-bottom: ${props => scale(props.theme.height.chromeBarBottom)};
  height: 100%;
  overflow: none;
  background: ${props => props.theme.color.bg};
  transition: padding 0.3s ease-out;

  ${media.smOnly`
    ${props =>
      props.noBarsOnMobile &&
      `
      padding-top: 0;
      padding-bottom: 0;
    `}
  `}
`;

export default ({ children, pageContext }) => {
  const isIndex =
    typeof pageContext.layout !== 'undefined' && pageContext.layout === 'index';

  return (
    <>
      <Head />
      <ThemeGlobalStyles />
      <ThemeProvider theme={theme}>
        <ApolloProvider>
          <CartProvider>
            <TimerContext.Provider>
              <ProductCategoriesProvider>
                  <Wrapper noBarsOnMobile={!isIndex}>
                    <ChromeBarTop hideOnMobile={!isIndex} />
                    {children}
                    <CookieNotice isIndex={isIndex} />
                    <ChromeBarBottom hideOnMobile={!isIndex} />
                  </Wrapper>
              </ProductCategoriesProvider>
            </TimerContext.Provider>
          </CartProvider>
        </ApolloProvider>
      </ThemeProvider>
    </>
  );
};

import { createGlobalStyle } from 'styled-components';
import styledNormalize from 'styled-normalize';

export default createGlobalStyle`
  ${styledNormalize}

  html {
    height: 100%;
    font-size: 16px;
  }

  body {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    color: #000;
    overflow: hidden;
  }

  * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  #___gatsby {
    height: 100%;

    > div {
      height: 100%;
    }
  }
`;

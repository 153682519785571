import React from 'react';
import { Link } from 'gatsby';
import styled, { css } from 'styled-components';
import { OutboundLink } from 'gatsby-plugin-google-analytics';

import scale from '../utils/scale';
import media from '../utils/mediaQueries';

import Text from './Text';
import Icon from './Icon';

export const buttonStyles = css`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-width: 1px;
  border-style: solid;
  padding: 0 ${scale(1.25)};
  height: ${props => scale(props.theme.height.buttonHeightMobile)};
  border-radius: ${props => props.theme.radius.base};
  text-decoration: none;

  ${props =>
    props.disabled &&
    `
    opacity: 0.5;
    cursor: default;
  `}

  ${media.md`
    padding: 0 ${scale(1)};
    height: ${props => scale(props.theme.height.buttonHeight)};
  `}

  svg {
    display: inline-block;
    width: 20px;
    height: 20px;
  }

  span {
    display: inline-block;
    margin-top: 2px;
    padding: 0 ${scale(0.5)};

    &::first-letter {
      text-decoration: underline;
    }
  }

  &.square {
    padding: 0;
    width: ${props => scale(props.theme.height.buttonHeightMobile)};
    flex-shrink: 0;
    flex-grow: 0;

    ${media.md`
      width: ${props => scale(props.theme.height.buttonHeight)};
    `}
  }
`;

const PrimaryButton = styled.button`
  ${buttonStyles}

  border-color: ${props => props.theme.color.border};
  background: ${props => props.theme.gradient.chrome};
  color: ${props => props.theme.color.base};

  ${props =>
    !props.disabled &&
    `
    cursor: pointer;

    &:hover, &:focus {
      background: ${props.theme.gradient.chromeHighlighted};
    }

    &:active {
      background: ${props.theme.gradient.chromeInverted};
    }
  `}
`;

const SecondaryButton = styled.button`
  ${buttonStyles}

  border-color: ${props => props.theme.color.borderMedium};
  background: ${props => props.theme.gradient.chromeLight};
  color: ${props => props.theme.color.grayDark};

  ${props =>
    !props.disabled &&
    `
    cursor: pointer;

    &:hover, &:focus {
      background: ${props.theme.gradient.chromeLightHighlighted};
    }

    &:active {
      background: ${props.theme.gradient.chromeLightInverted};
    }
  `}
`;

export default ({
  useLink,
  label,
  href,
  icon,
  children,
  isActive,
  secondary,
  ...props
}) => {
  const ButtonBase = secondary ? SecondaryButton : PrimaryButton;
  const isSquareButton =
    typeof label === 'undefined' && typeof children === 'undefined';

  let buttonProps = {
    ...props,
    className: isSquareButton ? `square ${props.className}` : props.className,
    onClick: !props.disabled ? props.onClick : null,
  };

  if (useLink && !props.disabled) {
    buttonProps = {
      ...buttonProps,
      as: Link,
      to: props.link,
    };
  }

  if (href && !props.disabled) {
    buttonProps = {
      ...buttonProps,
      href,
      as: OutboundLink,
    };
  }

  return (
    <ButtonBase {...buttonProps}>
      {icon && <Icon type={icon} />}
      {children}
      {label && (
        <Text as="span" textSize="sm" extended>
          {label}
        </Text>
      )}
    </ButtonBase>
  );
};

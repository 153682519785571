import React, { useState, useEffect, useContext, useMemo } from 'react';
import {  useMutation, useQuery, useLazyQuery } from 'react-apollo';
import {  UPDATE_CART_BUYER_IDENTIY,  CREATE_NEW_CART, REMOVE_CART_LINE_ITEMS, GET_CART_WITH_COUNTRY_CODE } from '../data/checkout';
import { GET_AVAILABLE_LOCATIONS } from '../data/currencies';
import Cookies from 'js-cookie';

export const markets = [
  {
    name: 'Germany',
    isoCode: ['DE'],
    label: 'DE',
    currency: {
      isoCode: 'EUR',
      symbol: '€',
      name: 'Euro'
    },
  },
  {
    name: 'EU',
    isoCode: ['AT', 'BE', 'BG', 'CY', 'CZ', 'DK', 'EE', 'ES', 'FI', 'FR', 'GR', 'HR', 'HU', 'IE', 'IT', 'LT', 'LU', 'LV', 'MT', 'NL', 'PL', 'PT', 'RO', 'SE', 'SI', 'SK'],
    label: 'EU',
    currency: {
      isoCode: 'EUR',
      symbol: '€',
      name: 'Euro'
    },
  },
  {
    name: 'United States',
    isoCode: ['US'],
    label: 'US',
    currency: {
      isoCode: 'USD',
      symbol: '$',
      name: 'US Dollar'
    },
  },
  {
    name: 'Japan',
    isoCode: ['JP'],
    label: 'JP',
    currency: {
      isoCode: 'JPY',
      symbol: '¥',
      name: 'Japanese Yen'
    },
  },
  {
    name: 'China',
    isoCode: ['CN'],
    label: 'CN',
    currency: {
      isoCode: 'CNY',
      symbol: '¥',
      name: 'Chinese Yuan'
    },
  },
  {
    name: 'Hong Kong',
    isoCode: ['HK'],
    label: 'HK',
    currency: {
      isoCode: 'HKD',
      symbol: 'HK$',
      name: 'Hong Kong Dollar'
    },
  },
  {
    name: 'South Korea',
    isoCode: ['KR'],
    label: 'KR',
    currency: {
      isoCode: 'KRW',
      symbol: '₩',
      name: 'South Korean Won'
    },
  },
  {
    name: 'United Kingdom',
    isoCode: ['GB'],
    label: 'GB',
    currency: {
      isoCode: 'GBP',
      symbol: '£',
      name: 'British Pound'
    },
  }
]

const countryCodes = ['DE', 'US', 'JP', 'CN', 'HK', 'GB']

const defaultContext = {
  cartId: null,
  country: {
    name: 'Germany',
    isoCode: ['DE'],
    label: 'DE',
    currency: {
      isoCode: 'EUR',
      symbol: '€',
      name: 'Euro'
    },
  }, 
  currency: {
    isoCode: 'EUR',
    symbol: '€',
    name: 'Euro'
  },
}

const CartContext = React.createContext(defaultContext)

export function useCart() {
  return useContext(CartContext)
}

export const CartProvider = ({ children }) => {
  const [cartId, setCartId] = useState(defaultContext.cartId);
  const [country, _setCountry] = useState(defaultContext.country);
  const [currency, _setCurrency] = useState(defaultContext.currency);
  const [cookieCountryCode, setcookieCountryCode] = useState(null);

  // queries
  const { called, loading, data: cartData, refetch, error } = useQuery(GET_CART_WITH_COUNTRY_CODE);
  const { called: locationsCalled, loading: locationsLoading, data: locationsData, error: locationsError } = useQuery(GET_AVAILABLE_LOCATIONS);

  const [executeCheckCartExists, {called: firstCall, loading: firstLoad, data: firstData, error: firstError }] = useLazyQuery(GET_CART_WITH_COUNTRY_CODE);
  
  const availableCurrencies = useMemo(() => {
    if (locationsLoading || locationsError) return []
    return locationsData.localization.availableCountries.map(country => country.currency).reduce((acc, curr) => {
      if (!acc.find(c => c.isoCode === curr.isoCode)) {
        acc.push(curr)
      }
      return acc
    }, [])

  }, [locationsData])

  const availableLocations = useMemo(() => {

    if (locationsLoading || locationsError) return []

    const locations = []
    markets.forEach(market => {
      if (locationsData.localization.availableCountries.find(country => market.isoCode.includes(country.isoCode))) {
        locations.push(market)
      }
    })

    return locations
  }, [locationsData])


  // mutations
  const [createNewCart, { data: createCartData, loading: createCartLoading, error: createCartError }] = useMutation(CREATE_NEW_CART);
  const [removeCartLineItems, { data: removeLineItemsData, loading: removeLineItemsLoading, error: removeLineItemsError }] = useMutation(REMOVE_CART_LINE_ITEMS);
  const [updateCartBuyerIdentity, { buyerData, buyerLoading, buyerError }] = useMutation(UPDATE_CART_BUYER_IDENTIY);


  useEffect(() => {
    // guess location if not already in cookies
    (async () => {
      if (Cookies.get('gmbh_guessed_country_code')) {
        const countryCode = JSON.parse(Cookies.get('gmbh_guessed_country_code'))
        setcookieCountryCode(countryCode)
      } else {
        const resIp = await fetch('https://api.ipify.org?format=json')
        const { ip } = await resIp.json()
        
        const resLoc = await fetch('/.netlify/functions/getLocationFromIp', {
          method: 'POST',
          body: JSON.stringify({ ipAddress: ip }),
        })

        const location = await resLoc.json()

        const { countryCode } = location || { countryCode: ['DE'] }

        const cC = countryCodes.find(cc => countryCode.includes(cc)) || 'DE'

        Cookies.set('gmbh_guessed_country_code', JSON.stringify(cC), { expires: 7 })
        setcookieCountryCode(countryCode)
      }
    })()
  }, [])


  const initializeCart = async () => {

    const newCountry = availableLocations.find(loc => loc.isoCode.includes(cookieCountryCode));
    if (!newCountry) return;  // Check if the country is found

    _setCountry(newCountry);
    const newCurrency = availableCurrencies.find(curr => curr.isoCode === newCountry.currency.isoCode);
    _setCurrency(newCurrency);


    const { data } = await createNewCart({
      variables: { countryCode: cookieCountryCode }
    });


    if (data && data.cartCreate && data.cartCreate.cart) {
      setCartId(data.cartCreate.cart.id);
      localStorage.setItem('shopify_cart_id', data.cartCreate.cart.id);
    }
  };

  useEffect(() => {
    if (!cookieCountryCode || cartId) return;


    const existingCartId = localStorage.getItem('shopify_cart_id');

    if (!existingCartId) {
      initializeCart();
      return;
    }
    if (!firstCall) {
      executeCheckCartExists({ variables: { cartId: existingCartId, countryCode: country ? country.isoCode[0] : cookieCountryCode } });
    }

    if (!firstLoad && firstData) {
      if (!firstData.node) {
        initializeCart();
      } else {
        setCartId(existingCartId);
      }
    }
  }, [cookieCountryCode, cartId, firstCall, firstLoad, firstData, firstError, availableLocations])


  useEffect(() => {
    // set country and currency based on buyerIdentity

    if (cartData?.node) {
      const { countryCode } = cartData.node.buyerIdentity
      const newCountry = availableLocations.find(loc => loc.isoCode.includes(countryCode))
      _setCountry(newCountry)
      const newCurrency = availableCurrencies.find(curr => curr.isoCode === newCountry.currency.isoCode)
      _setCurrency(newCurrency)
    }
  }, [cartData])

  // Effect to load or update cart when country code changes
  useEffect(() => {
    if (cartId && !cartData) {
      refetch({
        cartId,
        countryCode: country ? country.isoCode[0] : cookieCountryCode

      })
    }
    else if (cartId && cartData && country) {
      updateCartBuyerIdentity({
        variables: {
          buyerIdentity: {
            countryCode: country.isoCode[0],
          },
          cartId: cartId,
          countryCode: country.isoCode[0]
        }
      })
    }
  }, [country, cartId, refetch])


  const setCurrency = (currency) => {
    const newCurrency = availableCurrencies.find(curr => curr.isoCode === currency)
    _setCurrency(newCurrency)

    const newCountryIsoCode = availableLocations.find(loc => loc.currency.isoCode === currency).isoCode[0]
    setCountry(newCountryIsoCode)
  }

  const setCountry = (countryLabel) => {
    const newCountry = availableLocations.find(loc => loc.label === countryLabel)
    _setCountry(newCountry)
  }

  const refetchCart = () => {

    cartId && refetch({
      cartId,
      countryCode: country ? country.isoCode[0] : cookieCountryCode
    })
  }

  const contextValue = {
    cartId,
    setCartId,
    cartData,
    loading,
    country,
    setCountry,
    availableLocations,
    currency,
    setCurrency,
    availableCurrencies,
    refetchCart,
  };

  return <CartContext.Provider value={contextValue}>{children}</CartContext.Provider>;
};


export default CartContext;

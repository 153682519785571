import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Helmet from 'react-helmet';

import universExtWoff2 from '../assets/fonts/364086_1_0.woff2';
import universExtWoff1 from '../assets/fonts/364086_1_0.woff';
import universExtBoldWoff2 from '../assets/fonts/364086_0_0.woff2';
import universExtBoldWoff1 from '../assets/fonts/364086_0_0.woff';

export default () => (
  <StaticQuery
    query={graphql`
      query LayoutQuery {
        defaults: allPrismicSiteSettings {
          edges {
            node {
              data {
                meta_title_default
                meta_description_default
                meta_image_default {
                  url
                }
              }
            }
          }
        }
      }
    `}
    render={pageData => {
      const defaults = pageData.defaults.edges[0].node.data;

      const safeTitle = defaults.meta_title_default.replace(/"/g, '&quot;');
      const safeDescription = defaults.meta_description_default.replace(
        /"/g,
        '&quot;'
      );

      return (
        <Helmet defaultTitle={safeTitle} titleTemplate={`%s – ${safeTitle}`}>
          <html lang="en" />

          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0, maximum-scale=1.0,user-scalable=0"
          />

          <meta property="og:title" content={safeTitle} />
          <meta property="og:image" content={defaults.meta_image_default.url} />

          <meta name="description" content={safeDescription} />
          <meta property="og:description" content={safeDescription} />

          <meta
            name="facebook-domain-verification"
            content="1yz5y2cqhs4rg2k4k2oqsj4vs1u467"
          />

          <style type="text/css">
            {`
              @import url('//hello.myfonts.net/count/364086');

              @font-face {
                font-family: 'Univers Extended';
                font-style: normal;
                font-weight: normal;
                src: local('UniversLTStd-Ex'),
                  url(${universExtWoff2}) format('woff2'),
                  url(${universExtWoff1}) format('woff');
              }

              @font-face {
                font-family: 'Univers Extended';
                font-style: normal;
                font-weight: bold;
                src: local('UniversLTStd-BoldEx'),
                  url(${universExtBoldWoff2}) format('woff2'),
                  url(${universExtBoldWoff1}) format('woff');
              }
            `}
          </style>
        </Helmet>
      );
    }}
  />
);

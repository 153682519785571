export default {
  breakpoints: ['40em', '60em', '72em'],
  space: [0, 8, 16, 24, 32],
  scale: 0.5, // (in em) => scale(1) = 0.5em = 8px

  height: {
    chromeBarTop: 3.5, // = scale(4)
    chromeBarBottom: 2.75,
    breadcrumbsBar: 4.75,
    breadcrumbsBarMobile: 5.25,
    sidebarMobile: 5.5,
    contentSafeAreaDeltaSm: 11.75, // = breadcrumbsBarMobile + sidebarMobile + 1
    contentSafeAreaDeltaMd: 12, // = chromeBarTop + chromeBarBottom + breadcrumbsBar + 1
    buttonHeight: 3.75,
    buttonHeightMobile: 4.25,
  },

  width: {
    sidebar: 21,
  },

  font: {
    base: 'Arial, sans-serif',
    extended: '"Univers Extended", sans-serif',
  },

  color: {
    bg: '#fff',
    base: '#000',
    gray: '#e8e8e8',
    grayMedium: '#ccc',
    grayDark: '#444',
    grayLight: '#f7f7f7',
    blue: '#113AD0',
    blueLight: '#f1f4ff',
    purple: '#6D18AE',
    red: '#D50000',
    redLight: '#ffdada',
    border: '#222',
    borderMedium: '#7e7e7e',
    borderLight: '#979797',
    borderLighter: '#dcdcdc',
    borderDark: '#000',
  },

  gradient: {
    chrome: 'linear-gradient(0deg, #949494, #EAEAEA 50%)',
    chromeInverted: 'linear-gradient(180deg, #949494 50%, #EAEAEA)',
    chromeHighlighted: 'linear-gradient(0deg, #999999, #F1F1F1 50%)',
    chromeLight: 'linear-gradient(0deg, #D8D8D8, #FFFFFF 50%)',
    chromeLightInverted: 'linear-gradient(180deg, #D8D8D8 50%, #FFFFFF)',
    chromeLightHighlighted: 'linear-gradient(0deg, #E0E0E0, #FFFFFF 50%)',
    chromeDark: 'linear-gradient(0deg, #838383, #D6D6D6 90%)',
  },

  radius: {
    base: '2px',
    large: '8px',
  },

  transition: {
    durationShort: 250,
    durationLong: 500,
  },
};
